export const VERSION = 'v 2.0.0'

export const SKYWATCH_API_VERSION = 3

export const STATES = {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FM: 'Federated States Of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
}

export const PAYMENT_METHODS = {
    applePay: {
        name: 'Apple pay',
    },
    credit: {
        name: 'Credit/debit',
    },
    googlePay: {
        name: 'Google pay',
    },
}

export const STATE_EXPIRY_TTL = 1000 * 60 * 15 // 15 minutes

export const THREE_WEEKS_IN_MS = 1000 * 60 * 60 * 24 * 7 * 3

export const MAX_FUTURE_DATE_DAYS = 60

export const PHONE_NUMBER_REGEX = /^[0-9*\+-]+$/

export const COUNTRIES = { US: 'US', CANADA: 'Canada', UK: 'UK' }

export const POBOX_REGEX =
    /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i;


export const EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const WEIRD_GENERATED_GUID = '00000000-0000-0000-0000-000000000000'

export const HULL_MAX_LENGTH_SERIAL_NUMBER = 16
export const HULL_MIN_LENGTH_SERIAL_NUMBER = 6
export const UK_HULL_MIN_LENGTH_SERIAL_NUMBER = 1

export const VIOLATIONS = [
    'Animal Collision',
    'At Fault Accident',
    'At Fault Accident Bodily Injury To Others',
    'At Fault Accident Physical Damage Only',
    'Attempting To Elude Officer',
    'Backing Illegally',
    'Careless/ Negligent Driving',
    'Cellular Telephone Infraction',
    'Comprehensive Claim',
    'Defective Equipment',
    'Deviating Lane Of Traffic',
    'Drive Under Influence Of Alcohol/ Drug',
    'Driving Against Traffic',
    'Driving Too Fast For Conditions',
    'Fail To Obey Traffic Control',
    'Failure To Control Vehicle',
    'Failure To Dim Lights',
    'Failure To Report Acciden',
    'Failure To Signal',
    'Failure To Stop',
    'Failure To Stop After Accident',
    'Failure To Stop For School Bus',
    'Failure To Yield',
    'Following Too Close',
    'Illegal Riding',
    'Illegal Turn',
    'Impeding Traffic',
    'Implied Consent / Refuse Test',
    'Improper Backing',
    'Improper Signal',
    'Inattentive Driving',
    'Not At Fault Accident',
    'Obstructing Traffic',
    'Obstructing View Or Control',
    'Open Intoxicant',
    'Oper After Revocation / Suspension',
    'Operating Without Lic / Exp Lic',
    'Passing Illegally',
    'Reckless / Negligent Driving',
    'Reckless Homicide',
    'Safety Violation',
    'Single Vehicle Accident',
    'Speeding 09 Over',
    'Speeding 10-19 Over',
    'Speeding 20 Over',
    'Unnecessary Acceleration',
]

export const AircraftLimitations = {
    SEL_NO_CFI_B1: `This application is for insurance for your personal use of non-owned aircraft, which is:
    A. any fixed wing, single engine land aircraft powered by a reciprocating piston engine; or
    B. any Sailplane or Glider
    that is licensed under a "Standard" Airworthiness Certificate, "Special" Airworthiness Certificate
    with a Light-sport Category, or "Special" Airworthiness Certificate with an Experimental
    Category and a Purpose of Operating Amateur-built Aircraft, Operating Kit-built Aircraft, or
    Operating Light-sport Aircraft, including any sailplane or glider with Special Airworthiness
    Certificate with Experimental Category with the use of Exhibition and Racing, other than any
    aircraft:
    (a) having a seating capacity (including crew seats) in excess of seven (7) seats; or
    (b) powered by an engine that exceeds five hundred horsepower (500HP).`,
    SES_NO_CFI_B2: `This application is for insurance for your personal use of non-owned aircraft, which is:
    A. any fixed wing, single engine land aircraft powered by a reciprocating piston engine;
    B. any fixed wing, single engine sea aircraft powered by a reciprocating piston engine; or
    C. any Sailplane or Glider
    that is licensed under a "Standard" Airworthiness Certificate, "Special" Airworthiness Certificate
    with a Light-sport Category, or "Special" Airworthiness Certificate with an Experimental
    Category and a Purpose of Operating Amateur-built Aircraft, Operating Kit-built Aircraft, or
    Operating Light-sport Aircraft, including any sailplane or glider with Special Airworthiness
    Certificate with Experimental Category with the use of Exhibition and Racing, other than any
    aircraft:
    (a) having a seating capacity (including crew seats) in excess of seven (7) seats; or
    (b) which is a land aircraft powered by an engine that exceeds five hundred horsepower
    (500HP) or a sea aircraft powered by an engine that exceeds three hundred fifty
    horsepower (350HP).`,
    MEL_NO_CFI_B3: `This application is for insurance for your personal use of non-owned aircraft, which is:
    A. any fixed wing, single engine land aircraft powered by a reciprocating piston engine;
    B. any non-pressurized multi-engine land aircraft powered by reciprocating piston engines; or
    C. any Sailplane or Glider
    that is licensed under a "Standard" Airworthiness Certificate, "Special" Airworthiness Certificate
    with a Light-sport Category, or "Special" Airworthiness Certificate with an Experimental
    Category and a Purpose of Operating Amateur-built Aircraft, Operating Kit-built Aircraft, or
    Operating Light-sport Aircraft, including any sailplane or glider with Special Airworthiness
    Certificate with Experimental Category with the use of Exhibition and Racing, other than any
    aircraft:
    (a) having a seating capacity (including crew seats) in excess of seven (7) seats; or
    (b) which is a single engine aircraft powered by an engine that exceeds five hundred
    horsepower (500HP) or a multi-engine aircraft powered by engines that exceed three
    hundred fifty horsepower (350HP) per engine.`,
    SES_MEL_NO_CFI_B4: `This application is for insurance for your personal use of non-owned aircraft, which is:
    A. any fixed wing, single engine land aircraft powered by a reciprocating piston engine;
    B. any fixed wing, single engine sea aircraft powered by a reciprocating piston engine;
    C. any non-pressurized multi-engine land aircraft powered by reciprocating piston engines; or
    D. any Sailplane or Glider
    that is licensed under a "Standard" Airworthiness Certificate, "Special" Airworthiness Certificate
    with a Light-sport Category, or "Special" Airworthiness Certificate with an Experimental
    Category and a Purpose of Operating Amateur-built Aircraft, Operating Kit-built Aircraft, or
    
    Operating Light-sport Aircraft, including any sailplane or glider with Special Airworthiness
    Certificate with Experimental Category with the use of Exhibition and Racing, other than any
    aircraft:
    (a) having a seating capacity (including crew seats) in excess of seven (7) seats; or
    (b) which is a single engine land aircraft powered by an engine that exceeds five hundred
    horsepower (500HP) or a single engine sea aircraft powered by an engine that exceeds
    three hundred fifty horsepower (350HP) or a multi-engine land aircraft powered by engines
    that exceed three hundred fifty horsepower (350HP) per engine.`,
    SEL_CFI_B5: `This application is for insurance for your personal use of, and Certified Flight Instructor services
    in, non-owned aircraft, which is:
    A. any fixed wing, single engine land aircraft powered by a reciprocating piston engine; or
    B. any Sailplane or Glider
    that is licensed under a "Standard" Airworthiness Certificate, "Special" Airworthiness Certificate
    with a Light-sport Category, or "Special" Airworthiness Certificate with an Experimental
    Category and a Purpose of Operating Amateur-built Aircraft, Operating Kit-built Aircraft, or
    Operating Light-sport Aircraft, including any sailplane or glider with Special Airworthiness
    Certificate with Experimental Category with the use of Exhibition and Racing, other than any
    aircraft:
    (a) having a seating capacity (including crew seats) in excess of seven (7) seats; or
    (b) powered by an engine that exceeds five hundred horsepower (500HP).`,
    SES_CFI_B6: `This application is for insurance for your personal use of, and Certified Flight Instructor services
    in, non-owned aircraft, which is:
    A. any fixed wing, single engine land aircraft powered by a reciprocating piston engine;
    B. any fixed wing, single engine sea aircraft powered by a reciprocating piston engine; or
    C. any Sailplane or Glider
    that is licensed under a "Standard" Airworthiness Certificate, "Special" Airworthiness Certificate
    with a Light-sport Category, or "Special" Airworthiness Certificate with an Experimental
    Category and a Purpose of Operating Amateur-built Aircraft, Operating Kit-built Aircraft, or
    Operating Light-sport Aircraft, including any sailplane or glider with Special Airworthiness
    Certificate with Experimental Category with the use of Exhibition and Racing, other than any
    aircraft:
    (a) having a seating capacity (including crew seats) in excess of seven (7) seats; or
    (b) which is a land aircraft powered by an engine that exceeds five hundred horsepower
    (500HP) or a sea aircraft powered by an engine that exceeds three hundred fifty
    horsepower (350HP).`,
    MEL_CFI_B7: `This application is for insurance for your personal use of, and Certified Flight Instructor services
    in, non-owned aircraft, which is:
    A. any fixed wing, single engine land aircraft powered by a reciprocating piston engine;
    B. any non-pressurized multi-engine land aircraft powered by reciprocating piston engines; or
    C. any Sailplane or Glider
    that is licensed under a "Standard" Airworthiness Certificate, "Special" Airworthiness Certificate
    with a Light-sport Category, or "Special" Airworthiness Certificate with an Experimental
    Category and a Purpose of Operating Amateur-built Aircraft, Operating Kit-built Aircraft, or
    Operating Light-sport Aircraft, including any sailplane or glider with Special Airworthiness
    Certificate with Experimental Category with the use of Exhibition and Racing, other than any
    aircraft:
    (a) having a seating capacity (including crew seats) in excess of seven (7) seats; or
    (b) which is a single engine aircraft powered by an engine that exceeds five hundred
    
    horsepower (500HP) or a multi-engine aircraft powered by engines that exceed three
    hundred fifty horsepower (350HP) per engine.`,
    SES_MEL_CFI_B8: `This application is for insurance for your personal use of, and Certified Flight Instructor services
    in, non-owned aircraft, which is:
    A. any fixed wing, single engine land aircraft powered by a reciprocating piston engine;
    B. any fixed wing, single engine sea aircraft powered by a reciprocating piston engine;
    C. any non-pressurized multi-engine land aircraft powered by reciprocating piston engines; or
    D. any Sailplane or Glider
    that is licensed under a "Standard" Airworthiness Certificate, "Special" Airworthiness Certificate
    with a Light-sport Category, or "Special" Airworthiness Certificate with an Experimental
    Category and a Purpose of Operating Amateur-built Aircraft, Operating Kit-built Aircraft, or
    Operating Light-sport Aircraft, including any sailplane or glider with Special Airworthiness
    Certificate with Experimental Category with the use of Exhibition and Racing, other than any
    aircraft:
    (a) having a seating capacity (including crew seats) in excess of seven (7) seats; or
    (b) which is a single engine land aircraft powered by an engine that exceeds five hundred
    horsepower (500HP) or a single engine sea aircraft powered by an engine that exceeds
    three hundred fifty horsepower (350HP) or a multi-engine land aircraft powered by engines
    that exceed three hundred fifty horsepower (350HP) per engine.`,
}

export const ExcludedUses = {
    SEL_OR_MEL_NO_CFI_A1: `This policy for which you are submitting this application does not apply to occurrences involving:
    Skydiving or skydiving related activities; power line, pipeline or highline patrol; aerial photography or
    cinematography requiring a FAA Certificate of Waiver; any form of hunting; animal herding; taxi,
    takeoff or alighting on water, while the aircraft is equipped with floats; taxi, takeoff or landing on
    snow or ice, while the aircraft is equipped with skis; any use involving a charge intended to result in
    financial profit to the Insured.`,
    SES_NO_CFI_A2: `This policy for which you are submitting this application does not apply to occurrences involving:
    Skydiving or skydiving related activities; power line, pipeline or highline patrol; aerial photography or
    cinematography requiring a FAA Certificate of Waiver; any form of hunting; animal herding; taxi,
    takeoff or landing on snow or ice, while the aircraft is equipped with skis; any use involving a charge
    intended to result in financial profit to the Insured.`,
    SEL_OR_MEL_CFI_A3: `This policy for which you are submitting this application does not apply to occurrences involving:
    Skydiving or skydiving related activities; power line, pipeline or highline patrol; aerial photography or
    cinematography requiring a FAA Certificate of Waiver; any form of hunting; animal herding; taxi,
    takeoff or alighting on water, while the aircraft is equipped with floats; taxi, takeoff or landing on
    snow or ice, while the aircraft is equipped with skis; any use involving a charge intended to result in
    financial profit to the Insured, except ground and flight instruction, review or evaluation services
    performed by the Named Insured while holding a currently effective Certified Flight Instructor
    Certificate.`,
    SES_CFI_A4: `This policy for which you are submitting this application does not apply to occurrences involving:
    Skydiving or skydiving related activities; power line, pipeline or highline patrol; aerial photography or
    cinematography requiring a FAA Certificate of Waiver; any form of hunting; animal herding; taxi,
    takeoff or landing on snow or ice, while the aircraft is equipped with skis; any use involving a charge
    intended to result in financial profit to the Insured, except ground and flight instruction, review or
    evaluation services performed by the Named Insured while holding a currently effective Certified
    Flight Instructor Certificate.`,
}

export const PersonalTooltip = `"Personal" means your use of a non-owned aircraft for personal pleasure or non-commercial purposes, 
including flight training or dual instruction you are receiving. 
It also includes volunteer operations such as Civil Air Patrol and personal use related to non-aviation business activities.
 Aircraft must not be flown by professional pilots employed for this purpose.`

export const CFITooltip = `"CFI & Personal" includes all uses covered under "Personal," 
such as personal pleasure, flight training, and non-aviation business use. 
Additionally, it allows you to provide ground and flight instruction, review, 
or evaluation services while holding a current Certified Flight Instructor Certificate. 
This is not a commercial aviation policy form and does not extend to other types of commercial operations.`

export const NOT_COVERED_PER_PASS_LIABILITY_ID =
    '74a1a7f1-a46c-4530-9770-26c48337e4c8'

export const BLACKLIST_COVERAGE_OPTIONS_AIRCRAFT_DAMAGE_LIABILITY = [
    "$200000",
    "$150000",
    "$125000",
    "$100000",
    "$80000",
    "$75000",
    "$70000",
    "$65000"
    ]